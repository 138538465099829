import React, { useState } from "react";
import {
  FilledInput,
  FormHelperText,
  IconButton,
  Stack,
  SvgIcon,
  SxProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { IC_HIDE_PASSWORD, IC_SHOW_PASSWORD } from "../assets";

export default function PFMInput(props: {
  label?: string;
  important?: boolean;
  text?: string;
  onUpdate?: (text: string) => void;
  placeholder?: string;
  startIcon?: any;
  buttonIcon?: any;
  buttonClick?: () => void;
  errorText?: string;
  helperText?: string;
  password?: boolean;
  onReturn?: any;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  startAdornment?: any;
  endAdornment?: any;
  disabled?: boolean;
  /**
   * Applied to the root element (Stack)
   */
  sx?: SxProps;
}) {
  const [passwordVisible, setPasswordVisible] = useState(false);

  function toggleVisibility() {
    setPasswordVisible(!passwordVisible);
  }

  return (
    <Stack sx={{ width: "100%", height: "100%", ...props.sx }} spacing={"6px"}>
      {props.label && (
        <Typography
          fontFamily={"Inter"}
          fontWeight={"medium"}
          fontSize={"14px"}
          sx={{ display: "flex" }}
        >
          {props.label}
          {props.important && <Typography color="error">*</Typography>}
        </Typography>
      )}
      <FilledInput
        onKeyDown={(ke) => {
          if (ke.key === "Enter" && props.onReturn) props.onReturn();
        }}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        multiline={props.multiline}
        rows={props.rows}
        value={props.text}
        onChange={(t) => props.onUpdate?.(t.currentTarget.value)}
        disableUnderline
        hiddenLabel
        size="small"
        type={props.password && !passwordVisible ? "password" : "text"}
        error={Boolean(props.errorText)}
        sx={{
          borderRadius: "8px",
          //height: "44px",
          minHeight: "44px",
          //maxHeight: "44px",
          px: "0",
        }}
        inputProps={{
          style: {
            paddingRight: "14px",
            paddingLeft: "14px",
            paddingTop: "10px",
            paddingBottom: "10px",
          },
        }}
        placeholder={props.placeholder}
        {...(props.startIcon
          ? {
              startAdornment: (
                <SvgIcon sx={{ ml: "14px", mr: 0 }}>
                  <props.startIcon />
                </SvgIcon>
              ),
            }
          : {})}
        {...(props.buttonIcon
          ? {
              endAdornment: (
                <IconButton onClick={props.buttonClick}>
                  <props.buttonIcon />
                </IconButton>
              ),
            }
          : {
              ...(props.password
                ? {
                    endAdornment: (
                      <Tooltip
                        title={
                          passwordVisible ? "Hide password" : "Show password"
                        }
                      >
                        <IconButton onClick={toggleVisibility}>
                          {passwordVisible ? (
                            <IC_HIDE_PASSWORD />
                          ) : (
                            <IC_SHOW_PASSWORD />
                          )}
                        </IconButton>
                      </Tooltip>
                    ),
                  }
                : {}),
            })}
        {...(props.startAdornment
          ? {
              startAdornment: <>{props.startAdornment}</>,
            }
          : undefined)}
        {...(props.endAdornment
          ? {
              endAdornment: <>{props.endAdornment}</>,
            }
          : undefined)}
      />
      {Boolean(props.errorText) && (
        <FormHelperText error>{props.errorText}</FormHelperText>
      )}
      {Boolean(props.helperText && !props.errorText) && (
        <FormHelperText>{props.helperText}</FormHelperText>
      )}
    </Stack>
  );
}
