// Import the functions you need from the SDKs you need
import { AppConfig } from "../config";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// Initialize Firebase
const app = initializeApp(AppConfig.firebaseConfig);
const auth = getAuth(app);

export async function GetCurrentToken() {
  return await auth.currentUser?.getIdToken(true);
}

export const FbApp = app;
export const FbAuth = auth;
export const FbStorage = getStorage(FbApp);
