import React from "react";
import { IC_CHECKBOX } from "../assets";
import {
  Checkbox,
  Stack,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";

export default function PFMCheckbox(props: {
  onUpdate?: (checked: boolean) => void;
  label?: string;
  value?: boolean;
  typographySx?: TypographyProps;
}) {
  const theme = useTheme();
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Checkbox
        checked={props.value}
        onChange={(c) => props.onUpdate?.(c.target.checked)}
        checkedIcon={<IC_CHECKBOX fill={theme.palette.primary.main} />}
        icon={<IC_CHECKBOX fill="#61646C" />}
      />
      {props.label && (
        <Typography
          fontSize={"14px"}
          color={props.value ? "#FCFCFD" : "#94969C"}
          sx={props.typographySx}
        >
          {props.label}
        </Typography>
      )}
    </Stack>
  );
}
