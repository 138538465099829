import React from "react";
import { ToggleButtonGroup, styled } from "@mui/material";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      height: "40px",
      border: "1px solid #333741",
      textTransform: "none",
      borderRadius: "0px",
      "&.Mui-disabled": {
        border: 0,
        borderRadius: "0px",
      },
      "&:first-child": {
        borderRadius: "8px 0px 0px 8px",
        border: "1px solid #333741",
      },
      "&:last-child": {
        borderRadius: "0px 8px 8px 0px",
        border: "1px solid #333741",
      },
    },
  })
);
